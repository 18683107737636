<template>
  <v-container class="mt-6">
    <v-row>
      <v-col cols="12">
        <base-card>
          <v-card-text class="ps-6 d-flex justify-space-between align-center flex-wrap">
            <div class="my-2">
              <h4 class="">
                Search by Spec
              </h4>
              <p class="gray--text text--darken-1 mb-0">
                48 results found
              </p>
            </div>
            <v-col
              cols="12"
              md="6"
              class="d-flex align-center flex-wrap justify-end"
            >
              <div class="grey--text text--darken-1 me-2 my-2 pr-2">
                Search All :
              </div>
              <v-col
                cols="12"
                md="4"
                class="pa-0 mr-6"
              >
                <v-select
                  class="border me-5"
                  :items="itemsFour"
                  dense
                  outlined
                  hide-details
                  flat
                />
              </v-col>
              <div class="grey--text text--darken-1 me-2 my-2">
                View :
              </div>
              <v-btn icon>
                <img v-svg-inline class="icon" src="@/assets/images/icons/grid.svg" alt=""/>
              </v-btn>
              <v-btn icon>
                <img v-svg-inline class="icon" src="@/assets/images/icons/menu.svg" alt=""/>
              </v-btn>
            </v-col>
          </v-card-text>
        </base-card>
      </v-col>
      <v-col cols="12">
        <div class="box-wrapper">
          <div class="box-overlay" :class="{ open: isSidebar }" @click="isSidebar = !isSidebar"></div>
          <v-col md="3" class="pl-0 py-0">
            <v-col class="box-sidebar pa-6 shadow-sm" :class="{ open: isSidebar }">
              <h5 class="pb-4 d-flex">
                Categories
                <v-spacer />
                <v-menu class="tooltip-menu">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          class="rounded box-shadow-none mr-1"
                          fab
                          color="info"
                          height="16"
                          width="16"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <v-icon size="10">mdi-filter</v-icon>
                        </v-btn>
                      </template>
                      <span>Filters</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in filters"
                      :key="index"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="rounded box-shadow-none"
                      fab
                      color="info"
                      height="16"
                      width="16"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="12">
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Clear Filters</span>
                </v-tooltip>
              </h5>
              <v-divider class="pb-3" />
              <template>
                <v-treeview
                  selectable
                  selected-color="primary"
                  :items="treemenu"
                />
              </template>
            </v-col>
          </v-col>

          <v-col
            md="9"
            class="box-content pr-0 py-0"
          >
            <div class="box-container">
              <v-row>
                <v-col cols="12">
                  <v-data-iterator
                    :items="items"
                    :items-per-page.sync="itemsPerPage"
                    :page.sync="page"
                    hide-default-footer
                  >
                    <template v-slot:default="props">
                      <v-row>
                        <v-col
                          v-for="(item, index) in props.items"
                          :key="index"
                          cols="12"
                          sm="4"
                          md="4"
                          lg="3"
                          xl="3"
                        >
                          <CardCart
                            :content-img="item.img"
                            :content-text="item.title"
                            :amount="item.amount"
                            :contentRating="item.rating"
                            :counter="item.qty"
                            @cartRemove="removeCart(item)"
                            @cartAdd="addCart(item)"
                          />
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:footer>
                      <v-row
                        class="my-5 me-1"
                        align="center"
                        justify="center"
                      >
                        <v-spacer />
                        <span class="mr-4 grey--text">
                          Page {{ page }} of {{ numberOfPages }}
                        </span>
                        <v-btn
                          fab
                          :disabled="page == 1"
                          small
                          color="primary"
                          class="mr-1 box-shadow-none"
                          @click="formerPage"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          :disabled="page == numberOfPages"
                          small
                          color="primary"
                          class="ml-1 box-shadow-none"
                          @click="nextPage"
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import CardCart from '@/components/cartCard/CardCart'
  export default {
    components: {
      CardCart,
    },
    data () {
      return {
        isSidebar: false,
        page: 1,
        itemsPerPage: 20,
        range: [0, 100, 500, 1000],
        itemsStock: ['All Stock', 'In Stock'],
        itemsTwo: ['Category', 'Manufacturer'],
        itemsFour: ['Name', 'Manufacturer', 'Price Low to High', 'Price High to Low'],
        // select: "",
        cartCount: 0,
        filterProductList: [],
        filterProduct: [],
        selected: [],
        filters: [
          { title: 'Add filter' },
          { title: 'Manage Filters' },
        ],
        treemenu: [
          {
            id: 1,
            name: 'Accessories',
            children: [
              {
                id: 2,
                name: 'Cable Locks',
                children: [
                  {
                    id: 3,
                    name: 'Availiability',
                    children: [
                      { id: 4, name: 'In Stock' },
                    ],
                  },
                  {
                    id: 5,
                    name: 'Brand Name',
                    children: [
                      { id: 6, name: 'Lenovo (10)' },
                      { id: 7, name: 'Compulocks (10)' },
                      { id: 8, name: 'StarTech.com (9)' },
                      { id: 9, name: 'HP (8)' },
                      { id: 10, name: 'Targus (8)' },
                      { id: 11, name: 'Noble (3)' },
                      { id: 12, name: 'Ergotron (1)' },
                    ],
                  },
                  {
                    id: 13,
                    name: 'Cable Length',
                    children: [
                      { id: 14, name: 'Under 1m (3)' },
                      { id: 15, name: '1.0m to 1.5m (2)' },
                      { id: 16, name: '1.5m to 2m (29)' },
                      { id: 17, name: '2.0m to 2.5m (10)' },
                    ],
                  },
                  {
                    id: 18,
                    name: 'Lock Configuration Type',
                    children: [
                      { id: 19, name: 'Combination Lock (17)' },
                      { id: 20, name: 'Keyed Lock (13)' },
                      { id: 21, name: 'Patented T-bar/Key Lock (3)' },
                      { id: 22, name: 'Master Keyed Lock (5)' },
                      { id: 22, name: 'Combination Lock/Keyed Lock (1)' },
                    ],
                  },
                  {
                    id: 23,
                    name: 'Locking Combination',
                    children: [
                      { id: 24, name: '4-digit (13)' },
                      { id: 25, name: '3-digit (11)' },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: 26,
            name: 'Audio / Visual',
            children: [
              {
                id: 27,
                name: 'Conference / Web Cameras',
                children: [
                  {
                    id: 28,
                    name: 'Availiability',
                    children: [
                      { id: 29, name: 'In Stock' },
                    ],
                  },
                  {
                    id: 30,
                    name: 'Brand Name',
                    children: [
                      { id: 31, name: 'Lenovo (10)' },
                      { id: 32, name: 'Compulocks (10)' },
                      { id: 33, name: 'StarTech.com (9)' },
                      { id: 34, name: 'HP (8)' },
                      { id: 35, name: 'Targus (8)' },
                      { id: 36, name: 'Noble (3)' },
                      { id: 37, name: 'Ergotron (1)' },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: 38,
            name: 'Cables',
            children: [
              {
                id: 39,
                name: 'Connector Cables',
                children: [
                  {
                    id: 40,
                    name: 'Availiability',
                    children: [
                      { id: 41, name: 'In Stock' },
                    ],
                  },
                  {
                    id: 30,
                    name: 'Brand Name',
                    children: [
                      { id: 42, name: 'Lenovo (10)' },
                      { id: 43, name: 'Compulocks (10)' },
                      { id: 44, name: 'StarTech.com (9)' },
                      { id: 45, name: 'HP (8)' },
                      { id: 46, name: 'Targus (8)' },
                      { id: 47, name: 'Noble (3)' },
                      { id: 48, name: 'Ergotron (1)' },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: 49,
            name: 'Cameras & Video',
            children: [
              {
                id: 50,
                name: 'Camera Lenses',
                children: [
                ],
              },
            ],
          },
          {
            id: 51,
            name: 'Computer Components',
            children: [
              {
                id: 52,
                name: 'Cases / Chasis',
                children: [
                ],
              },
            ],
          },
          {
            id: 53,
            name: 'Computer Systems',
            children: [
              {
                id: 54,
                name: 'Barebone / Blade',
                children: [
                ],
              },
            ],
          },
          {
            id: 55,
            name: 'Consumables & Stationery',
            children: [
              {
                id: 56,
                name: '3D Printer Cartridges',
                children: [
                ],
              },
            ],
          },
          {
            id: 57,
            name: 'Networking',
            children: [
              {
                id: 58,
                name: 'Access Points',
                children: [
                ],
              },
            ],
          },
          {
            id: 59,
            name: 'Phones',
            children: [
              {
                id: 60,
                name: 'Mobile Phones',
                children: [
                ],
              },
            ],
          },
          {
            id: 61,
            name: 'Point of Sales',
            children: [
              {
                id: 62,
                name: 'Accessories',
                children: [
                ],
              },
            ],
          },
          {
            id: 63,
            name: 'Power Devices',
            children: [
              {
                id: 64,
                name: 'AC Adapters',
                children: [
                ],
              },
            ],
          },
          {
            id: 65,
            name: 'Printers / Scanners',
            children: [
              {
                id: 66,
                name: 'Dot Matrix',
                children: [
                ],
              },
            ],
          },
          {
            id: 67,
            name: 'Projectors',
            children: [
              {
                id: 68,
                name: 'DLP / LCD Projectors',
                children: [
                ],
              },
            ],
          },
          {
            id: 69,
            name: 'Security',
            children: [
              {
                id: 70,
                name: 'Accessories',
                children: [
                ],
              },
            ],
          },
          {
            id: 71,
            name: 'Software',
            children: [
              {
                id: 72,
                name: 'General',
                children: [
                ],
              },
            ],
          },
          {
            id: 73,
            name: 'Storage',
            children: [
              {
                id: 74,
                name: 'Host Bus Licensing',
                children: [
                ],
              },
            ],
          },
          {
            id: 75,
            name: 'Telecommunications',
            children: [
              {
                id: 76,
                name: 'Analog & Digital Phones',
                children: [
                ],
              },
            ],
          },
          {
            id: 77,
            name: 'Warranty & Services',
            children: [
              {
                id: 78,
                name: 'Warranties',
                children: [
                ],
              },
            ],
          },

        ],
      }
    },
    computed: {
      ...mapGetters(['getProducts', 'getCategory', 'getRatings']),

      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
      this.items = this.getProducts
      // this.items = this.getProducts.slice(0, 15).map(n => n);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      ...mapActions(['addCart', 'removeCart']),
      relevance (amount) {
        if (this.select == 'Low to High') {
          this.items = this.items.sort((a, b) => (a[amount] < b[amount] ? -1 : 1))
        } else {
          this.items = this.items.sort((a, b) => (a[amount] > b[amount] ? -1 : 1))
        }
      },
      // fullProducts () {
      //   this.items = this.getProducts
      // },
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },

      filterCategory (category) {
        this.items = this.getProducts.filter((x) => this.selected.includes(x.category))
        if (this.selected.length <= 0) {
          this.items = this.getProducts
        }
      },
      filterRatings (rating) {
        this.items = this.getProducts.filter((x) => this.selected.includes(x.rating))
        if (this.selected.length <= 0) {
          this.items = this.getProducts
        }
      },
      productRate (rate) {
        this.items = this.getProducts.filter((x) => x.rating == rate.rates)
        // this.items = this.filterProduct.sort((a, b) => a[rate.rates] < b[rate.rates] ? -1 : 1)
      },
    },
  }
</script>
